<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-button style="margin: 20px; border-radius: 4px" type="primary" size="mini" @click="openAddOrUpdatePage(0)">+ 新增</el-button>
        </div>
        <div style="margin-left: 20px; margin-right: 20px; margin-top: -20px" class="ui-layout-content--full ui-layout-flex--1" ref="testCo">
            <ex-search-table-pagination
                ref="searchTable"
                :paramsMethod="searchParams"
                :fetch="$client.getUserList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
            >
                <!--<template slot="orgForm">
                    <el-form-item label="组织架构">
                        <el-select
                            ref="orgSel"
                            v-model="orgName"
                            placeholder="请选择"
                            style="width:300px"
                        >
                            <el-tree
                                ref="orgTree"
                                :data="orgList"
                                :props="treeOrops"
                                node-key="id"
                                :expand-on-click-node="false"
                                :load="loadOrgAtUnit"
                                @node-click="orgClick"
                                :highlight-current="true"
                                lazy
                            >
                            </el-tree>
                            <el-option style="display: none" v-model="orgName"> </el-option>
                        </el-select>
                    </el-form-item>
                </template>-->
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="160"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="重置密码" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_reset_password"
                                    @click="resetPassword(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="编辑" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_edit"
                                    @click="openAddOrUpdatePage(1,scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="deleteHandle(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!--新增 / 修改-->
            <el-dialog
                v-bind="addOrUpdateDialog"
                width="760px"
                :title="addOrUpdateDialog.title"
                v-if="addOrUpdateDialog.visible"
                :visible.sync="addOrUpdateDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-or-update
                    ref="AddOrUpdate"
                    :id="addOrUpdateDialog.currentId"
                    :type="addOrUpdateDialog.type"
                ></add-or-update>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addOrUpdateDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="save" size="mini">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import AddOrUpdate from './user-add-or-update';
import * as dayjs from 'dayjs';

export default {
    data() {
        return {
            selectedRecords: null,
            addOrUpdateDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: ''
            },
            treeOrops: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            orgList: [],
            orgId: '', // 下拉框双向绑定变量
            orgName: '' // 下拉框双向绑定变量
        };
    },
    computed: {
        columns() {
            return [
                /*{
                    type: 'selection'
                },*/
                /*{
                        prop: 'userId',
                        label: 'ID',
                    },
                    {
                        prop: 'username',
                        label: '用户名',
                    },*/
                {
                    prop: 'realName',
                    width: '240',
                    label: '姓名'
                },
                {
                    prop: 'orgList',
                    width: '320',
                    label: '隶属部门',
                    formatter: (row, column, cellValue) => {
                        let names = cellValue.map(item => {
                            return item.name;
                        });
                        return names.join(',');
                    }
                },
                /*{
                        prop: 'email',
                        label: '邮箱',
                    },*/
                {
                    prop: 'mobile',
                    width: '240',
                    label: '手机号'
                },
                {
                    prop: 'status',
                    width: '120',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        if (cellValue === 1) {
                            return '正常';
                        }
                        return '禁用';
                    }
                },
                {
                    prop: 'createTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                            return time;
                        }
                        return '';
                    },
                    label: '创建时间',
                }
            ];
        },
        formOptions() {
            return {
                resetBtnCallback: () => {
                    this.orgId = '';
                    this.orgName = '';
                },
                forms: [
                    {
                        prop: 'orgId',
                        slotName: 'orgForm'
                    },
                    {
                        prop: 'username',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入用户名',
                        label: ' '
                    },
                    {
                        prop: 'realName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入姓名',
                        label: ' '
                    },
                ]
            };
        }
    },
    components: {
        AddOrUpdate
    },
    created() {
        this.reloadTreeData();
    },
    methods: {
        searchParams(params) {
            params.orgId = this.orgId;
            return params;
        },
        /* 申领人员下拉的点击事件 */
        orgClick(data) {
            this.orgId = data.id;
            this.orgName = data.name;
            this.$refs.orgSel.blur();
        },
        reloadTreeData() {
            this.$client.getOrgUnitListByAdmin({parent: -1}).then(data => {
                const children = [];
                for (const item of data.list) {
                    item.isLeaf = item.isLeaf === 1 ? true : false;
                    children.push(item);
                }
                this.orgList = children;
            });
        },
        loadOrgAtUnit(node, resolve) {
            if (!node.data.id) {
                resolve([]);
                return;
            }
            this.$client.getOrgUnitListByAdmin({parent: node.data.id ? node.data.id : -1}).then(data => {
                const list = [];
                for (const item of data.list) {
                    item.isLeaf = item.isLeaf === 1 ? true : false;
                    list.push(item);
                }
                resolve(list);
            });
        },
        //新增 / 修改 页面
        openAddOrUpdatePage(status, row) {
            //默认新增 status=1时修改
            let id = 0;
            let title = '新增';
            let type = 'add';
            if (status) {
                //修改
                if (!row || !row.userId) {
                    return;
                }
                id = row.userId;
                title = '修改';
                type = 'edit';
            }
            this.addOrUpdateDialog = {
                title,
                currentId: id,
                visible: true,
                type
            };
            if (status) {
                this.$nextTick(() => {
                    this.$refs.AddOrUpdate.init(row);
                });
            } else {
                this.$nextTick(() => {
                    this.$refs.AddOrUpdate.init();
                });
            }
        },
        // 新增 / 修改
        save() {
            this.$refs.AddOrUpdate.dataFormSubmit(rest => {
                this.addOrUpdateDialog.visible = false;
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        resetPassword(row) {
            if (!row || !row.userId) {
                return;
            }
            let ids = [];
            ids.push(row.userId);
            this.$confirm(`确定对选中用户进行密码重置吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.batchResetPassword(ids).then(data => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            })
                .catch(() => {});
        },
        // 删除
        deleteHandle(row) {
            if (!row || !row.userId) {
                return;
            }
            let userIds = [];
            userIds.push(row.userId);
            this.$confirm(`确定删除选中用户`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delUser(userIds).then(data => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.$refs.searchTable.searchHandler();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            })
                .catch(() => {});
        },
        selectHandle(val) {
            this.selectedRecords = val;
        },
        test() {
            let node = this.$refs.testCo;
        },
    },
    mounted() {
        this.test();
    }
};
</script>

