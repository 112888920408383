<template>
    <div>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            size="mini"
            :disabled="type === 'detail'"
            label-width="120px"
            @keyup.enter.native="dataFormSubmit()"
        >
            <!--<el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'用户名'" prop="username">
                        <el-input v-model="dataForm.username"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>-->
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'手机号'" prop="mobile">
                        <el-input v-model="dataForm.mobile"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'姓名'" prop="realName">
                        <el-input v-model="dataForm.realName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'密码'" prop="password">
                        <el-input v-model="dataForm.password"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'确认密码'" prop="comfirmPassword">
                        <el-input v-model="dataForm.comfirmPassword"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'邮箱'" prop="email">
                        <el-input v-model="dataForm.email"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item label="隶属组织">
                    <el-select
                        ref="orgSel"
                        v-model="dataForm.orgName"
                        placeholder="请选择"
                        style="width:100%;"
                    >
                        <el-tree
                            ref="orgTree"
                            :data="orgList"
                            :props="treeOrops"
                            node-key="id"
                            :expand-on-click-node="false"
                            :load="loadOrgAtUnit"
                            @node-click="orgClick"
                            :highlight-current="true"
                            lazy
                        >
                        </el-tree>
                        <el-option style="display: none" v-model="dataForm.orgName"> </el-option>
                    </el-select>
                </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item label="角色" size="mini" prop="roleIdList">
                        <el-checkbox-group v-model="dataForm.roleIdList">
                            <el-checkbox v-for="role in roleList" :key="role.roleId" :label="role.roleId">{{ role.roleName }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'状态'" prop="status">
                        <el-radio-group v-model="dataForm.status">
                            <el-radio :label="0">禁用</el-radio>
                            <el-radio :label="1">正常</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="8"></el-col>
            </el-row>


        </el-form>
    </div>
</template>

<script>
import {isEmail, isMobile} from '@/utils/validation/function';

export default {
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        const validatePassword = (rule, value, callback) => {
            if (!this.dataForm.id && !/\S/.test(value)) {
                callback(new Error('密码不能为空'));
            } else {
                callback();
            }
        };
        const validateComfirmPassword = (rule, value, callback) => {
            if (!this.dataForm.id && !/\S/.test(value)) {
                callback(new Error('确认密码不能为空'));
            } else if (this.dataForm.password !== value) {
                callback(new Error('确认密码与密码输入不一致'));
            } else {
                callback();
            }
        };
        const validateEmail = (rule, value, callback) => {
            if (!isEmail(value)) {
                callback(new Error('邮箱格式错误'));
            } else {
                callback();
            }
        };
        const validateMobile = (rule, value, callback) => {
            if (!isMobile(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        return {
            roleList: [],
            treeOrops: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            orgList: [],
            dataForm: {
                id: 0,
                orgList: [],
                orgName: '',
                userId: 0,
                username: '',
                realName: '',
                password: '',
                comfirmPassword: '',
                salt: '',
                email: '',
                mobile: '',
                roleIdList: [],
                status: 1
            },
            dataRule: {
                username: [
                    {required: true, message: '用户名不能为空', trigger: 'blur'}
                ],
                realName: [
                    {required: true, message: '姓名不能为空', trigger: 'blur'}
                ],
                password: [
                    {validator: validatePassword, trigger: 'blur'}
                ],
                comfirmPassword: [
                    {validator: validateComfirmPassword, trigger: 'blur'}
                ],
                /*email: [
                        {required: true, message: '邮箱不能为空', trigger: 'blur'},
                        {validator: validateEmail, trigger: 'blur'}
                    ],*/
                mobile: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {validator: validateMobile, trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        /* 申领人员下拉的点击事件 */
        orgClick(data) {
            this.dataForm.orgList = [{id: data.id}];
            this.dataForm.orgName = data.name;
            this.$refs.orgSel.blur();
        },
        reloadTreeData() {
            this.$client.getOrgUnitListByAdmin({parent: -1}).then(data => {
                const children = [];
                for (const item of data.list) {
                    item.isLeaf = item.isLeaf === 1 ? true : false;
                    children.push(item);
                }
                this.orgList = children;
            });
        },
        loadOrgAtUnit(node, resolve) {
            if (!node.data.id) {
                resolve([]);
                return;
            }
            this.$client.getOrgUnitListByAdmin({parent: node.data.id ? node.data.id : -1}).then(data => {
                const list = [];
                for (const item of data.list) {
                    item.isLeaf = item.isLeaf === 1 ? true : false;
                    list.push(item);
                }
                resolve(list);
            });
        },
        init(item) {
            this.reloadTreeData();
            this.dataForm.id = item ? item.userId : 0;
            this.$client.getRoleList().then((data) => {
                this.roleList = data && data.code === 0 ? data.data.records : [];
            }).then(() => {
                this.$nextTick(() => {
                    this.$refs.dataForm.resetFields();
                });
            }).then(() => {
                if (this.dataForm.id) {
                    this.dataForm.realName = item.realName;
                    this.dataForm.username = item.username;
                    this.dataForm.email = item.email;
                    this.dataForm.mobile = item.mobile;
                    this.dataForm.status = item.status;
                    this.dataForm.orgList = item.orgList;
                    let orgNames = item.orgList.map(item => {
                        return item.name;
                    });
                    this.dataForm.orgName = orgNames.join(',');
                    this.$client.getUserById(this.dataForm.id).then((data) => {
                        if (data && data.code === 0) {
                            this.dataForm.roleIdList = data.user.roleIdList;
                        }
                    });
                }
            });
        },
        // 新增 / 修改
        dataFormSubmit(cb) {
            const param = this.dataForm;
            //默认用户名使用手机号
            param.username = param.mobile;
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = param.id ? this.$client.updateUser : this.$client.saveUser;
                    param.userId = param.id || undefined;
                    submit(param).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                            });
                            cb();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        },
    },
    created() {
        this.dataForm.id = this.id;
    }
};
</script>
